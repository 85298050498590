var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-div",
      class: _vm.navOpen ? "sidebar-div-opened" : "sidebar-div-closed",
    },
    [
      _c("div", { staticClass: "p-3 mr-auto", attrs: { id: "portal-logo" } }, [
        _c("img", { attrs: { src: require("@/assets/whitelogo.png") } }),
      ]),
      _c(
        "div",
        { staticClass: "expand-nav-div" },
        [
          _c(
            "b-iconstack",
            {
              staticClass: "cursor-pointer",
              on: {
                click: function ($event) {
                  _vm.navOpen = !_vm.navOpen
                },
                mouseover: function ($event) {
                  _vm.expandNavHover = true
                },
                mouseout: function ($event) {
                  _vm.expandNavHover = false
                },
              },
            },
            [
              _c("b-icon", {
                style:
                  _vm.expandNavHover || _vm.navOpen
                    ? "color:var(--blue)"
                    : "color:#88A1DE",
                attrs: { icon: "circle-fill", scale: "1.25" },
              }),
              !_vm.navOpen
                ? _c("b-icon", {
                    staticStyle: { color: "white" },
                    attrs: { icon: "chevron-right", scale: "0.75" },
                  })
                : _c("b-icon", {
                    staticStyle: { color: "white" },
                    attrs: { icon: "chevron-left", scale: "0.75" },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { attrs: { id: "menu" } }, [
        _vm.onProviderLogin
          ? _c("div", [
              _c(
                "a",
                { staticClass: "side-nav-link router-link-active selected" },
                [
                  _c("b-icon", {
                    attrs: { icon: "person", scale: "1.5", "shift-v": "1" },
                  }),
                  _vm.navOpen
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center overflow-hidden ml-3",
                        },
                        [_vm._v(" Login")]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "neg-radius-active" }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    class:
                      "side-nav-link " +
                      (_vm.activeLink == "providerportal" ||
                      _vm.activeLink == "provider-dashboard"
                        ? "active-sidebar-link"
                        : ""),
                    attrs: { to: "/provider-portal/", exact: "" },
                  },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "layout-wtf",
                        scale: "1.5",
                        "shift-v": "1",
                      },
                    }),
                    _vm.navOpen
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center overflow-hidden ml-3",
                          },
                          [_vm._v(" Dashboard")]
                        )
                      : _vm._e(),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.activeLink == "providerportal" ||
                            _vm.activeLink == "provider-dashboard",
                          expression:
                            "activeLink == 'providerportal' || activeLink == 'provider-dashboard'",
                        },
                      ],
                      staticClass: "neg-radius-active",
                    }),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    class:
                      "side-nav-link " +
                      (_vm.activeLink == "provider-preauths"
                        ? "active-sidebar-link"
                        : ""),
                    attrs: { to: "/provider-portal/preauths/" },
                  },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "shield-plus",
                        scale: "1.5",
                        "shift-v": "1",
                      },
                    }),
                    _vm.navOpen
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center overflow-hidden ml-3",
                          },
                          [_vm._v(" Preauthorize")]
                        )
                      : _vm._e(),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeLink == "provider-preauths",
                          expression: "activeLink == 'provider-preauths'",
                        },
                      ],
                      staticClass: "neg-radius-active",
                    }),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    class:
                      "side-nav-link " +
                      (_vm.activeLink == "estimates"
                        ? "active-sidebar-link"
                        : ""),
                    attrs: { to: "/provider-portal/estimates/" },
                  },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "receipt-cutoff",
                        scale: "1.5",
                        "shift-v": "1",
                      },
                    }),
                    _vm.navOpen
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center overflow-hidden ml-3",
                          },
                          [_vm._v(" Estimates")]
                        )
                      : _vm._e(),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeLink == "estimates",
                          expression: "activeLink == 'estimates'",
                        },
                      ],
                      staticClass: "neg-radius-active",
                    }),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    class:
                      "side-nav-link " +
                      (_vm.activeLink == "provider-reports"
                        ? "active-sidebar-link"
                        : ""),
                    attrs: { to: "/provider-portal/reports/" },
                  },
                  [
                    _c("b-icon", {
                      attrs: {
                        icon: "bar-chart-fill",
                        scale: "1.5",
                        "shift-v": "1",
                      },
                    }),
                    _vm.navOpen
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center overflow-hidden ml-3",
                          },
                          [_vm._v(" Reports")]
                        )
                      : _vm._e(),
                    _c("div", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeLink == "provider-reports",
                          expression: "activeLink == 'provider-reports'",
                        },
                      ],
                      staticClass: "neg-radius-active",
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }