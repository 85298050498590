var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sidebar-div", attrs: { id: "portal-sidebar" } },
    [
      _c(
        "div",
        { attrs: { id: "menu" } },
        [
          _c(
            "router-link",
            { staticClass: "side-nav-link", attrs: { to: "/claims/" } },
            [
              _c(
                "div",
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "file-earmark-medical",
                      scale: "1.3",
                      "shift-v": "1",
                    },
                  }),
                  _vm._v(" Claims"),
                ],
                1
              ),
            ]
          ),
          _vm.isViewerOfPath("/issues-claims/")
            ? _c(
                "router-link",
                {
                  staticClass: "side-nav-link",
                  attrs: { to: "/issues-claims/" },
                },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "exclamation-triangle",
                          scale: "1.2",
                          "shift-v": "1",
                        },
                      }),
                      _vm._v(" Issues"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isViewerOfPath("/workers/")
            ? _c(
                "router-link",
                { staticClass: "side-nav-link", attrs: { to: "/workers/" } },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: { icon: "people", scale: "1.3", "shift-v": "1" },
                      }),
                      _vm._v(" Workers"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isViewerOfPath("/me/")
            ? _c(
                "router-link",
                { staticClass: "side-nav-link", attrs: { to: "/me/" } },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "person-check",
                          scale: "1.3",
                          "shift-v": "1",
                        },
                      }),
                      _vm._v(" My Work"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            { staticClass: "side-nav-link", attrs: { to: "/practices/" } },
            [
              _c(
                "div",
                [
                  _c("b-icon", {
                    attrs: { icon: "shop", scale: "1.2", "shift-v": "1" },
                  }),
                  _vm._v(" Practices"),
                ],
                1
              ),
            ]
          ),
          _c(
            "router-link",
            { staticClass: "side-nav-link", attrs: { to: "/payers/" } },
            [
              _c(
                "div",
                [
                  _c("b-icon", {
                    attrs: {
                      icon: "shield-plus",
                      scale: "1.3",
                      "shift-v": "1",
                    },
                  }),
                  _vm._v(" Payers"),
                ],
                1
              ),
            ]
          ),
          _vm.isViewerOfPath("/reports/")
            ? _c(
                "router-link",
                { staticClass: "side-nav-link", attrs: { to: "/reports/" } },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "bar-chart-fill",
                          scale: "1.2",
                          "shift-v": "1",
                        },
                      }),
                      _vm._v(" Reports"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "router-link",
            {
              staticClass: "side-nav-link",
              attrs: { to: "/codelookup/", target: "_blank" },
            },
            [
              _c(
                "div",
                [
                  _c("b-icon", {
                    attrs: { icon: "search", scale: "1.2", "shift-v": "1" },
                  }),
                  _vm._v(" Code Lookup"),
                ],
                1
              ),
            ]
          ),
          _vm.isViewerOfPath("/admin/")
            ? _c(
                "router-link",
                { staticClass: "side-nav-link", attrs: { to: "/admin/" } },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: { icon: "gear", scale: "1.3", "shift-v": "1" },
                      }),
                      _vm._v(" Settings"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.isViewerOfPath(_vm.providerPortalLink)
            ? _c(
                "router-link",
                {
                  staticClass: "side-nav-link",
                  attrs: { to: _vm.providerPortalLink, target: "_blank" },
                },
                [
                  _c(
                    "div",
                    [
                      _c("b-icon", {
                        attrs: {
                          icon: "box-arrow-right",
                          scale: "1.3",
                          "shift-v": "1",
                        },
                      }),
                      _vm._v(" Provider Portal"),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }