var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "alert-div" },
    _vm._l(_vm.alertMessages, function (alert, index) {
      return _c(
        "b-alert",
        {
          key: index,
          attrs: { show: _vm.showAlerts, dismissible: "", variant: "danger" },
        },
        [_c("b", [_vm._v(_vm._s(alert))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }