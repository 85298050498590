var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("div", { attrs: { id: "header" } }, [
        _c("div", { attrs: { id: "logo" } }, [
          _c("img", {
            staticClass: "ml-3 p-2",
            attrs: { src: _vm.publicPath + "logo.png" },
          }),
        ]),
      ]),
      _c("sidebar"),
      _c(
        "div",
        { staticClass: "login-wrapper" },
        [
          _c("show-general-errors"),
          _c("div", { staticClass: "login-box" }, [
            _c(
              "div",
              { staticStyle: { "font-weight": "300", "font-size": "36px" } },
              [_vm._v("Worker Login")]
            ),
            _c("form", { staticClass: "pl-5 pt-1" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-column mb-2" },
                [
                  _c(
                    "label",
                    { staticClass: "mb-1", attrs: { for: "username" } },
                    [_vm._v("Email or username:")]
                  ),
                  _c("b-input", {
                    attrs: {
                      autofocus: "",
                      state: _vm.showingError
                        ? false
                        : _vm.buttonText == "Success"
                        ? true
                        : null,
                    },
                    model: {
                      value: _vm.username,
                      callback: function ($$v) {
                        _vm.username = $$v
                      },
                      expression: "username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex flex-column mb-4" },
                [
                  _c(
                    "label",
                    { staticClass: "mb-1", attrs: { for: "password" } },
                    [_vm._v("Password:")]
                  ),
                  _c("b-input", {
                    attrs: {
                      state: _vm.showingError
                        ? false
                        : _vm.buttonText == "Success"
                        ? true
                        : null,
                      type: "password",
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        return _vm.login.apply(null, arguments)
                      },
                    },
                    model: {
                      value: _vm.password,
                      callback: function ($$v) {
                        _vm.password = $$v
                      },
                      expression: "password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "text-right" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "avo-basic-btn",
                      attrs: { variant: "outline-primary" },
                      on: { click: _vm.login },
                    },
                    [_vm._v(_vm._s(_vm.buttonText))]
                  ),
                ],
                1
              ),
              _vm._m(0),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { attrs: { id: "footer" } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex justify-content-end mt-1 mr-1" }, [
      _c("div", { staticClass: "avo-text-light-small mr-1" }, [
        _vm._v("To log in as a provider, "),
      ]),
      _c(
        "a",
        { staticClass: "small-link", attrs: { href: "/provider-login/" } },
        [_vm._v(" Click Here")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }